<template>
  <div class="container-fluid pt-4 pb-4" v-loading="isLoading">
    <app-title :title="`Hello, ${user.first_name}`" />
    <div class="row mt-4">
      <div class="col-12 col-lg-6 mb-4 mt-2">
        <stat-item :item="news" title="News" collection="news" />
      </div>
      <div class="col-12 col-lg-6 mb-4 mt-2">
        <stat-item :item="library" title="Library" collection="library" />
      </div>
      <div class="col-12 col-lg-6 mb-4 mt-2">
        <stat-item :item="users" title="Users" collection="users" />
      </div>
      <div class="col-12 col-lg-6 mb-4 mt-2">
        <app-card :show-footer="false" @clicked="openMatomo">
          <template #title> Analytics </template>
          <template #body>
            <p class="mb-4">To access BATTERS traffic statistics, please visit the dedicated reporting on Matomo. </p>
            <a href="https://www.analytics.weloop.org/" target="_blank"><button class="btn-sm-accent">See statistics</button></a>
          </template>
        </app-card>
      </div>
    </div>
  </div>
</template>

<script>
import Database from '@/utils/services/Database';
import StatItem from '@/components/items/Stat';

export default {
  name: 'AdminDashboard',
  components: {
    StatItem,
  },
  data() {
    return {
      items: [],
      isLoading: false,
      user: {},
      news: {
        total: 0,
        pending: 0,
        approved: 0,
        last_updated: {
          date: '',
          time: '',
        },
      },
      library: {
        total: 0,
        pending: 0,
        approved: 0,
        last_updated: {
          date: '',
          time: '',
        },
      },
      users: {
        total: 0,
        verified: 0,
        unverified: 0,
        last_updated: {
          date: '',
          time: '',
        },
      },
    };
  },
  async created() {
    this.isLoading = true;
    this.user = await this.$store.dispatch('getUser');
    await this.getData();
    this.isLoading = false;
  },
  methods: {
    async getData() {
      this.isLoading = true;
      const res = await Database.getAll('users', {
        type: 'summary',
      });

      this.news = res.news;
      this.library = res.library;
      this.users = res.users;

      this.isLoading = false;
    },
    openMatomo() {
      window.open('https://www.analytics.weloop.org/', '_blank');
    },
  },
};
</script>