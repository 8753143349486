<template>
  <app-card @clicked="$router.push({ name: `Admin${title}` })" :show-footer="false" >
    <template #title>{{ title }}</template>
    <template #body>
      <div class="row stat-item-row">
        <div class="col-10">
          <p class="mb-0">Total</p>
        </div>
        <div class="col-2">
          <el-tag>{{ item.total }}</el-tag>
        </div>
      </div>
      <div class="row stat-item-row">
        <div class="col-10">
          <p class="mb-0">Pending</p>
        </div>
        <div class="col-2">
          <el-tag type="danger">{{ item.pending }}</el-tag>
        </div>
      </div>
      <div class="row stat-item-row">
        <div class="col-10">
          <p class="mb-0">Approved</p>
        </div>
        <div class="col-2">
          <el-tag type="success">{{ item.approved }}</el-tag>
        </div>
      </div>
      <div class="row stat-item-row text-small">
        <div class="col-6">
          <p class="mb-0">{{ footer.text }}</p>
        </div>
        <div class="col-6 text-end pe-4">
          <p class="mb-0">{{ footer.date }}</p>
        </div>
      </div>
    </template>
  </app-card>
</template>

<script>
export default {
  name: 'StatItem',
  props: ['item', 'title', 'collection'],
  computed: {
    footer() {
      const text =
        this.collection === 'users' ? 'Last registration' : 'Last submission';
      return {
        text,
        date: `${this.item.last_updated.date} - ${this.item.last_updated.time}`,
      };
    },
  },
};
</script>

<style lang="scss">
.stat-item-row {
  &:not(:last-child) {
    border-bottom: 1px solid #e9ecef;
    padding: 0.3rem 0rem;
  }
  &:last-child {
    padding-top: 0.75rem;
    padding-bottom: 0.5rem;
  }
  .el-tag {
    font-size: 0.8rem;
    font-weight: 500;
  }
}
</style>
